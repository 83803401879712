
.audi-font-order-1 {
  font-size: 28px;
  line-height: 44px;
  letter-spacing: -0.015em;
}
.audi-font-order-2 {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.015em;
}
.audi-font-order-3 {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.015em;
}
.audi-font-order-4 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.015em;
}

@media screen and (min-width: 1024px) {
  .audi-font-order-1 {
    font-size: 36px;
    line-height: 1.2;
  }
  .audi-font-order-2 {
    font-size: 28px;
    line-height: 44px;
  }
}

@media screen and (min-width: 1440px) {
  .audi-font-order-1 {
    font-size: 40px;
    line-height: 1.2;
  }
  .audi-font-order-2 {
    font-size: 32px;
    line-height: 1.2;
  }
  .audi-font-order-3 {
    font-size: 24px;
    line-height: 36px;
  }
  .audi-font-order-4 {
    font-size: 18px;
    line-height: 28px;
  }
}

@media screen and (min-width: 1920px) {
  .audi-font-order-1 {
    font-size: 44px;
    line-height: 68px;
  }
  .audi-font-order-2 {
    font-size: 36px;
    line-height: 42px;
  }
  .audi-font-order-3 {
    font-size: 24px;
    line-height: 36px;
  }
  .audi-font-order-4 {
    font-size: 20px;
    line-height: 32px;
  }
}

@mixin textbutton-audi() {
  text-decoration: none;
  display: flex;
  padding: 0;
  border: 0;
  color: var(--black);
  cursor: pointer;

  &:after {
    content: "";
    background: center no-repeat url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='13' viewBox='0 0 8 13'%3E%3Cpath stroke='%23000000' stroke-width='1px' fill='none' d='M1.5,1l5.485,5.504l-5.48,5.496' /%3E%3C/svg%3E");
    flex-shrink: 0;
    width: 0.5em;
    margin-top: 0.1em;
    margin-inline-start: 0.25em;
    transition: transform 250ms ease;
  }

  &:hover {
    color: var(--grey-70);

    &:after {
      transform: translate(0.3em);
    }
  }
}